<template>
    <div class="player-wrapper md:h-screen md:relative">
        <div :id="id"></div>
    </div>
</template>

<script>
// import OvenPlayer from "../../modified-ovenplayer/dev/ovenplayer";
// import OvenPlayer from "../../modified-ovenplayer/dist/ovenplayer";

export default {
    props: {
        id: {
            type: String,
            default: "player-id-1"
        },
        text: {
            type: String,
            default: ""
        },
        sources: {
            type: Array,
            default: [{ type: "hls", file: "https://vod.ome-test-2.bemyvega.dev/default_app_TZP2jwRJFmxjHDGgrGzb34-ptz/2022-11-18T10:58:25+0000/llhls.m3u8" }]
        },
        controls: {
            type: Boolean,
            default: true
        },
        autoRefresh: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            player: null
        };
    },
    mounted() {
        const newWindowObject = window;
        console.log("hola", newWindowObject);
        const ovenplayer = newWindowObject.OvenPlayer;

        if (this.autoRefresh) {
            const loadOvenPlayer = () => {
                if (this.player) {
                    this.player.remove();
                }

                this.player = ovenplayer.create(this.id, {
                    autoStart: true,
                    autoFallback: true,
                    mute: false,
                    waterMark: {
                        text: this.text,
                        font: {
                            "font-size": "20px",
                            color: "#fff",
                            "font-weight": "bold"
                        },
                        position: "top-left"
                    },
                    sources: this.sources,
                    controls: this.controls
                });

                // Reload OvenPlayer when error occured.
                this.player.on("error", function () {
                    // Wait 2 sec and relaod.
                    setTimeout(function () {
                        loadOvenPlayer();
                    }, 2000);
                });
            };

            loadOvenPlayer();
        } else {
            this.player = ovenplayer.create(this.id, {
                autoStart: true,
                autoFallback: true,
                mute: false,
                waterMark: {
                    text: this.text,
                    font: {
                        "font-size": "20px",
                        color: "#fff",
                        "font-weight": "bold"
                    },
                    position: "top-left"
                },
                sources: this.sources,
                controls: this.controls
            });
        }
    },
    methods: {
        getMute() {
            return this.player.getMute();
        },
        setMute(isMute) {
            return this.player.setMute(isMute);
        },
        getVolume() {
            return this.player.getVolume();
        },
        setVolume(volume) {
            return this.player.setVolume(volume);
        },
        getCurrentSource() {
            return this.player.getCurrentSource();
        }
    }
};
</script>

<style>
.player-wrapper {
    display: flex;
}
</style>
